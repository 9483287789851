import React from 'react';
import { Link } from 'react-router-dom';

// Bulma Imports
// import { Container } from 'reactbulma';

// Geist Imports
import { Text, Tag, Dot } from "@geist-ui/react";

// Ant Design Imports

// Component Imports

// Styled Imports

// Variables

class Foot extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="columns" style={{fontSize: '14px'}}>
                        <div className="column is-two-fifths">
                            <span className="copyright bold">Copyright © 2019 – 2021 Project Aeolus</span>
                            <br />Modernizing and automating drone technology<br />
                            <br />For any inquiries, contact <a href="mailto:contact@project-aeolus.com">contact@project-aeolus.com</a>
                            <br />
                            Designed by <a href="https://ryanwans.com?ref=aeolus">Ryan Wans</a><br/>
                            <br />
                            <Tag type="success"><Dot type="success">All systems operational.</Dot></Tag>
                        </div>
                        <div className="column">
                            <Text h5 style={{fontWeight: '500', fontSize: '14px', marginBottom: '15px'}}>Company</Text>
                            <Link className="subfoot" to="/about">About Us</Link><br />
                            <Link className="subfoot" to="/graphics">Graphics</Link><br />
                            <Link className="subfoot" to="/careers">Careers</Link><br />
                            <Link className="subfoot" to="/news">News</Link>
                        </div>
                        <div className="column">
                            <Text h5 style={{fontWeight: '500', fontSize: '14px', marginBottom: '15px'}}>Quick Access</Text>
                            <Link className="subfoot" to="/tech">Technology</Link><br />
                            <Link className="subfoot" to="/products">Products</Link><br />
                            <Link className="subfoot" to="/services">Services</Link>
                        </div>
                        <div className="column">
                            <Text h5 style={{fontWeight: '500', fontSize: '14px', marginBottom: '15px'}}>Help</Text>
                            <Link className="subfoot" to="/support">Support</Link><br />
                            <Link className="subfoot" to="/contact">Contact</Link>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Foot;