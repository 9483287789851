import React from 'react';

// Bulma Imports
// import { Column } from 'reactbulma';

// Geist Imports
import { Text, Card, Display, Image, Divider, Button as GButton } from '@geist-ui/react';
import RotateCw from '@geist-ui/react-icons/rotateCw'

// Ant Design Imports
import { Button, Space } from 'antd';

// Component Imports
import hero from '../img/ComplexHero.svg'
import DroneLander from '../img/droneLandAnimation.svg';
import RemoteComms from '../img/remoteComms.svg'
import DtPAnim from '../img/dtpCommsAnim.svg';
import interLink from '../img/interLinking.svg';
import dashSkeleton from '../img/dashSkeleton.svg';
import numbers from '../img/numbers.svg';
import flowDiagram from '../img/flowDiagram.svg';
import exampleFlow from '../img/ExampleFlow.png';
import earth from '../img/earth.svg';
import multileg from '../img/multileg.svg';

import Tagline from './Tagline';

// Styled Imports
import Typed from 'react-typed';

// Variables
const GETCODE = [
    `
    getDrone(id: "ow7ye348", count: 1, sort: "last") { <br/>
        &emsp;lastCharge,<br/>
        &emsp;lastSeen,<br/>
        &emsp;fleet<br/>
    }
    `
];
const GRAPHCODE = [
    `
    ^5000lastCharge: ${Date.now()},<br/> 
    ^1100lastSeen: "ewik38e47ik46",<br/>
    ^500fleet: "Accessory Fleet"
    `,

];

window.typed = window.typed || {};

class Index extends React.Component {
    render() {
        return (
            <div>
                <div className="hero-main hero dotted-bg-light" style={{minHeight: '520px'}}>
                    <div className="hero-body is-halfheight">
                        <div className="container">
                            <div className="columns" style={{paddingTop: '35px', paddingBottom: '25px'}}>
                                <div className="column is-two-fifths">
                                    <Text h1 style={{fontWeight: '600'}}>
                                        Reinventing How Drone Fleets Are Managed
                                    </Text>
                                    <br /><br />
                                    <Text h3 style={{fontWeight: '500'}}>
                                        Project Aeolus works to modernize and automate drone fleets, creating continually-developing and ever-optimizing systems.
                                    </Text>
                                    <Space style={{marginTop: '70px'}}>
                                        <Button type="primary" style={{fontWeight: '600'}}>Our Solutions</Button>
                                        <Button type="text" style={{fontWeight: '600'}}>Reach Out &rarr;</Button>
                                    </Space>
                                </div>
                                <div className="column is-three-fifths" style={{textAlign: 'right'}}>
                                    <img src={hero} style={{width: '99%'}} alt="Hero" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '35px'}} className="container has-text-centered">
                    <Text h1>Discover The Capabilities of Project Aeolus</Text>
                    <div className="columns" style={{marginTop: '25px'}}>
                        <div className="column is-one-quarter">
                            <a href="#1">
                                <Card hoverable style={{fontSize: '15px', cursor: 'pointer'}}>
                                    <b>1. </b> Autonomous Charging
                                </Card>
                            </a>
                        </div>
                        <div className="column is-one-quarter">
                        <a href="#2">
                                <Card hoverable style={{fontSize: '15px', cursor: 'pointer'}}>
                                    <b>2. </b> Communications
                                </Card>
                            </a>
                        </div>
                        <div className="column is-one-quarter">
                            <a href="#3">
                                <Card hoverable style={{fontSize: '15px', cursor: 'pointer'}}>
                                    <b>3. </b> Analytics &amp; Automation
                                </Card>
                            </a>
                        </div>
                        <div className="column is-one-quarter">
                        <a href="#4">
                                <Card hoverable style={{fontSize: '15px', cursor: 'pointer'}}>
                                    <b>4. </b> Dependency Free
                                </Card>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '35px', alignContent: 'center'}} className="container has-text-centered" id="1">
                    <span className="hammer-line" />
                    <br />
                    <span className="hammer-box">1</span>
                    <Text h2>Autonomous Charging</Text>
                    <Text p style={{fontSize: '14px', maxWidth: '800px', display: 'inline-block', marginBottom: '25px'}}>
                        Having someone power off the drone, take out and charge the battery, put it back in, and send it back off is a time consuming 
                        and inefficient process. Project Aeolus eliminates the human interaction needed when handling drones, full autonomizing the 
                        charging process. 
                    </Text>
                    <div className="columns">
                        <div className="column is-half" style={{alignContent: 'center', textAlign: 'center'}}>
                            <img alt="Drone Animation" src={DroneLander} style={{maxWidth: '425px'}} />
                        </div>
                        <div className="column is-half has-text-left">
                            <Text h3 style={{marginTop:'20px'}}>No Human Intervention</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Once the drone pad detects that the drone has landed, it will begin charging and communicating with the drone.
                            </Text>
                            <br />
                            <Text h3 style={{marginTop:'20px'}}>Battery Optimization</Text>
                            <Text p style={{fontSize: '14px'}}>
                                The drone pad will charge the drone at a precise rate and wattage that will maximize batter lifespan per its chemsitry
                            </Text>
                            <br />
                            <Text h3 style={{marginTop:'20px'}}>Precise Charge Metrics</Text>
                            <Text p style={{fontSize: '14px'}}>
                                During charging, the drone pad will monitor and record a variety of charge and environment variables to improve the charging.
                                These metrics will also get sent back to the dashboard for further analysis and visualization.
                            </Text>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '75px'}} className="container has-text-centered" id="2">
                    <span className="hammer-line" />
                    <br />
                    <span className="hammer-box">2</span>
                    <Text h2>Communications</Text>
                    <Text p style={{fontSize: '14px', maxWidth: '800px', display: 'inline-block', marginBottom: '25px'}}>
                        The ability to communicate with the drone pads are an imperative feature when it comes to things like 
                        automation, optimization, and visualization. It also enables you to send data to the drone, at any time 
                        and from anywhere.
                    </Text>
                    <br/>
                    <span className="hammer-line-grey" />
                    <br />
                    <span className="hammer-box-grey" />
                    <div className="columns" style={{textAlign: 'center', alignContent: 'center', justifyContent: 'center', minHeight: '240px'}}>
                        <div className="column" style={{textAlign: 'right'}}>
                            <img src={RemoteComms} style={{maxWidth: '1000px', maxHeight: '200px'}} alt="Remote Communications" />
                        </div>
                        <div className="column" style={{maxWidth: '1px', alignSelf: 'center', display: 'inline-flex', height: '100%!important', position: 'relative'}}>
                            <span className="hammer-line-dash is-hidden-mobile" />
                        </div>
                        <div className="column" style={{textAlign: 'left'}}>
                            <Text h3 style={{marginTop:'20px'}}>Remote Applications</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Project Aeolus products can be outfitted with satellite transceivers, enabling you to send and receive data from the drone 
                                pad from anywhere in the world. This allows for truly remote and stand-alone drone applications. We also use proprietary 
                                software to minimize data packets being sent to and from the pad, saving you potentially tens of thousands of dollars 
                                a year in communications expenses.
                            </Text>
                        </div>
                    </div>
                    <span className="hammer-box-grey is-hidden-mobile" />
                    <div className="columns" style={{textAlign: 'center', alignContent: 'center', justifyContent: 'center', transform: "translateY(-2px)", minHeight: '240px'}}>
                        <div className="column" style={{textAlign: 'right'}}>
                            <img src={DtPAnim} style={{maxWidth: '1000px', maxHeight: '200px'}} alt="Remote Applications" />
                        </div>
                        <div className="column" style={{maxWidth: '1px', alignSelf: 'center', display: 'inline-flex', height: '100%!important', position: 'relative'}}>
                            <span className="hammer-line-dash is-hidden-mobile" />
                        </div>
                        <div className="column" style={{textAlign: 'left'}}>
                            <Text h3 style={{marginTop:'20px'}}>Drone &amp; Pad Communications</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Our drone pads can communicate with the drone on a variety of wavelengths to transfer data. If your drone is recording 
                                flight telemetry data, it can easily be sent to the drone pad, which can then relay it back to the dashboard. Additionally, 
                                you can send data to the drone from the dashboard for things like routing updates or it's next flight information.
                            </Text>
                        </div>
                    </div>
                    <span className="hammer-box-grey is-hidden-mobile" />
                    <div className="columns" style={{textAlign: 'center', alignContent: 'center', justifyContent: 'center', transform: "translateY(-2px)", minHeight: '240px'}}>
                        <div className="column" style={{textAlign: 'right'}}>
                            <img src={interLink} style={{maxWidth: '1000px', maxHeight: '200px'}} alt="Multiple Products In a Row" />
                        </div>
                        <div className="column" style={{maxWidth: '1px', alignSelf: 'center', display: 'inline-flex', height: '100%!important', position: 'relative'}}>
                            <span className="hammer-line-dash is-hidden-mobile" />
                        </div>
                        <div className="column" style={{textAlign: 'left'}}>
                            <Text h3 style={{marginTop:'20px'}}>Local Data Management</Text>
                            <Text p style={{fontSize: '14px'}}>
                                For applications that are less remote-oriented, we make it easy to link products up on a local network and run 
                                Project Aeolus software locally. This is useful for keeping all your data onsite, minimizing communication 
                                costs, and maximizing efficiency. You can use Project Aeolus' software locally to run analytics and analysis 
                                onsite, while also keeping a copy in the cloud.
                            </Text>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '0px'}} className="container has-text-centered" id="3">
                    <span className="hammer-line-aftergrey" />
                    <br />
                    <span className="hammer-box">3</span>
                    <Text h2>Analytics &amp; Automation</Text>
                    <Text p style={{fontSize: '14px', maxWidth: '800px', display: 'inline-block', marginBottom: '25px'}}>
                        Project Aeolus provides end-to-end solutions for data aggregation, management, analysis, and automation. Our powerful automation 
                        engine makes it easy to create further autonomy within your drone systems.
                    </Text>
                    <Display shadow caption={<p style={{fontSize: '13px'}}>Sample Skeleton of Project Aeolus' Dashboard</p>} style={{width: '100%'}}>
                        <Image.Browser url="https://dashboard.project-aeolus.com/home" anchorProps={{ rel: 'nofollow' }}>
                            <Image width={800} height={436} src={dashSkeleton} style={{objectFit: 'unset!important'}} />
                        </Image.Browser>
                    </Display>
                    <br />
                    <Divider><Text h3>We'll Crunch The Numbers For You</Text></Divider>
                    <div className="columns" style={{marginTop: '50px'}}>
                        <div className="column is-half">
                            <img src={numbers} style={{maxHeight:'350px'}} alt="Charts Analysis" />
                        </div>
                        <div className="column is-half has-text-left">
                            <Text h3>Analytical Systems</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Our robust and powerful analytics engine provides you detailed and in-depth insights into the performance and behavior 
                                of every part in your system.
                            </Text>
                            <br />
                            <Text h3>Real-time Processing</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Get the numbers you need the second they come in. Our real-time systems allow for you to react to sudden changes on the fly.
                            </Text>
                            <br />
                            <Text h3>Advanced Visualizations</Text>
                            <Text p style={{fontSize: '14px'}}>
                                The Project Aeolus dashboard has a powerful visualization tool to let you run regressions, compare individual data points, and more.
                            </Text>
                        </div>
                    </div>
                    <br />
                    <Divider style={{marginTop: '100px'}}><Text h3>Create Fully Autonomous Systems</Text></Divider>
                    <div className="columns" style={{marginTop: '50px'}}>
                        <div className="column is-half has-text-left">
                            <Text h3>Design Your Flows</Text>
                            <Text p style={{fontSize: '14px'}}>
                                The Project Aeolus dashboard makes it easy to create detailed flowchart diagrams. This allows you to create chains 
                                of events where propogation is dependent on event output.
                            </Text>
                            <br />
                            <Text h3>Scheduling</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Scheduling the execution of events or flows in intervals allows you to regularly update drone pads, request data 
                                in a scheduled pattern, and automate repititive tasks.
                            </Text>
                            <br />
                            <Text h3>Take Full Control</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Automation tasks can include events like sending health checks, send data to the Project Aeolus Analytics Engine, 
                                send a notification to your team memebers, execute serverless functions, and firing a webhook to your API.
                            </Text>
                        </div>
                        <div className="column is-half">
                            <img src={flowDiagram} style={{maxHeight:'380px'}} alt="Example Flow Charts" />
                        </div>
                    </div>
                    <Display caption={<p style={{fontSize: '13px'}}>Sample Automation Flowchart</p>} style={{width: '100%', marginTop: '55px'}}>
                        <Image src={exampleFlow} style={{objectFit: 'unset!important', maxWidth: '800px'}} />
                    </Display>
                    <br />
                    <Divider style={{marginTop: '100px'}}><Text h3>Integrate Existing Infrastructures</Text></Divider>
                    <div className="columns is-hidden-mobile" style={{marginTop: '50px'}}>
                        <div className="column is-half">
                            <div className="browser-window" style={{height: '300px'}}>
                                <div className="browser-row">
                                    <div className="browser-column" style={{float: 'left', textAlign: 'left', width: '15%', minWidth: '100px'}}>
                                        <span className="browser-dot" style={{background:"#ED594A", marginLeft: '5px'}}></span>
                                        <span className="browser-dot" style={{background:"#FDD800"}}></span>
                                        <span className="browser-dot" style={{background:"#5AC05A"}}></span>
                                    </div>
                                    <div className="browser-column middle" style={{float: 'left'}}>
                                        GET Request
                                    </div>
                                </div>
                                <div className="browser-content" style={{padding: '15px', textAlign:'left'}}>
                                    <span className="ryancode" style={{display: 'block'}}>
                                        &#123;<br/>
                                            <span className="ryancode indented">
                                                <Typed strings={GETCODE} typeSpeed={50} typedRef={(typed) => { window.typed.left = typed; }} />
                                            </span><br />
                                        &#125; 
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="column is-half">
                            <div className="browser-window" style={{height: '300px', position: 'relative'}}>
                                <div className="browser-row">
                                    <div className="browser-column" style={{float: 'left', textAlign: 'left', width: '15%', minWidth: '100px'}}>
                                        <span className="browser-dot" style={{background:"#ED594A", marginLeft: '5px'}}></span>
                                        <span className="browser-dot" style={{background:"#FDD800"}}></span>
                                        <span className="browser-dot" style={{background:"#5AC05A"}}></span>
                                    </div>
                                    <div className="browser-column middle" style={{float: 'left'}}>
                                        GraphQL Response
                                    </div>
                                </div>
                                <div className="browser-content" style={{padding: '15px', textAlign:'left', position: 'relative'}}>
                                    <span className="ryancode" style={{display: 'block'}}>
                                        &#123;<br/>
                                            <span className="ryancode indented">
                                                <Typed strings={GRAPHCODE} typeSpeed={10} typedRef={(typed) => { window.typed.right = typed; }} />
                                            </span><br />
                                        &#125;
                                    </span>
                                </div>
                                <GButton onClick={window.typed.replay} style={{position: 'absolute', bottom: '10px', right: '10px'}} iconRight={<RotateCw size={18} />} auto size="small" >
                                    REPLAY
                                </GButton>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="columns" style={{marginTop: '50px'}}>
                        <div className="column is-one-third has-text-centered">
                            <Text h3>Webhooks</Text>
                            <Text p style={{fontSize:'14px'}}>Integreate Webhooks into your automation flows and events to securely POST data to your services.</Text>
                        </div>
                        <div className="column is-one-third has-text-centered">
                            <Text h3>GraphQL API</Text>
                            <Text p style={{fontSize:'14px'}}>Securely access the Project Aeolus GraphQL API to query any data we store for you.</Text>
                        </div>
                        <div className="column is-one-third has-text-centered">
                            <Text h3>Websockets</Text>
                            <Text p style={{fontSize:'14px'}}>Stream live data from the Projeect Aeolus API to your data firehose or services.</Text>
                        </div>
                    </div>
                </div>
                <div style={{marginTop: '100px', marginBottom: '125px'}} className="container has-text-centered" id="4">
                    <span className="hammer-line" />
                    <br />
                    <span className="hammer-box">4</span>
                    <Text h2>Dependency Free</Text>
                    <Text p style={{fontSize: '14px', maxWidth: '800px', display: 'inline-block', marginBottom: '25px'}}>
                        Becuase our products can be powered via solar panel and utilize satellite networks for communications, they can run standalone and 
                        completely dependency-free, remotely.
                    </Text>
                    <div className="columns">
                        <div className="column is-half" style={{alignContent: 'center', textAlign: 'center'}}>
                            <img alt="Earth" src={earth} style={{maxWidth: '100%'}} />
                        </div>
                        <div className="column is-half has-text-left">
                            <Text h3 style={{marginTop:'40px'}}>Battery Technology</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Project Aeolus drone pads can last up to 5 days on the battery without sunlight or additional charging. This enables you 
                                to set them in completely remote and desolate location, knowing they will operate reliably.
                            </Text>
                            <br />
                            <Text h3 style={{marginTop:'20px'}}>Solar Powered</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Some models come shipped with a solar panel and port on the box to plug it in. The solar panel itself has the ability to 
                                fully charge the pad in just 3 hours in full sunlight. They can also be connected to a pre-exsiting solar panel 
                                network or powered from an external source.
                            </Text>
                            <br />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-half has-text-left">
                            <Text h3 style={{marginTop:'40px'}}>Multi-leg Journeys</Text>
                            <Text p style={{fontSize: '14px'}}>
                                The pads can be places in a line for the drone to follow, allowing for the drone to hop from pad to pad, completing a long 
                                distance journey. Becuase the pads are stand alone, all the drone has to do is land, charge, and start back on its way.
                            </Text>
                            <br />
                            <Text h3 style={{marginTop:'20px'}}>Industrial Usage</Text>
                            <Text p style={{fontSize: '14px'}}>
                                Another use case is industrial usage. It's easy to setup a Project Aeolus drone pad and allow the drone to carry out its 
                                operations while retreating to the pad once finished. This makes it versatile for locations where there might not be 
                                electricity or other means of charging.
                            </Text>
                            <br />
                        </div>
                        <div className="column is-half" style={{alignContent: 'center', textAlign: 'center'}}>
                            <img alt="Multi-Leg" src={multileg} style={{maxWidth: '90%'}} />
                        </div>
                    </div>
                </div>
                <Tagline message="Interested in learning more?" />
            </div>
        )
    }
}

export default Index;

/*
<div className="browser-window">
                        <div className="browser-row">
                            <div className="browser-column" style={{float: 'left', textAlign: 'left', width: '15%', minWidth: '100px'}}>
                                <span className="browser-dot" style={{background:"#ED594A", marginLeft: '5px'}}></span>
                                <span className="browser-dot" style={{background:"#FDD800"}}></span>
                                <span className="browser-dot" style={{background:"#5AC05A"}}></span>
                            </div>
                            <div className="browser-column middle" style={{float: 'left'}}>
                                Project Aeolus Dashboard
                            </div>
                        </div>
                        <div className="browser-content">
                            <img src={dashSkeleton} alt="dashboard" className="browser-content" />
                        </div>
                    </div>
*/