import React from 'react';

import { Container, Section } from 'reactbulma';
import { Text, Button, Spacer, Description, Input, Divider, Textarea } from '@geist-ui/react';

import * as emailjs from 'emailjs-com';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: '',email: '',subject: '',message: '', company: ''};
        this.resetForm = this.resetForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    resetForm() {this.setState({name: '',email: '',subject: '',message: '', company: '', });}
    handleChange(event) {this.setState({ [event.target.name]: event.target.value });}

    render() {
        // eslint-disable-next-line
        const { name, email, subject, message, company, sentMessage } = this.state;
        return (
            <div style={{position:'relative', width: '100%', marginBottom: '0px'}} className="dotted-bg-scatter">
                    <Section style={{height: '100%'}}>
                        <Container style={{height:'100%'}}>
                            <Text h1>Contact Team Aeolus</Text>
                            <Spacer y={2} />
                            <div className="columns">
                                <div className="column is-half">
                                    <div className="pre-card pre-bigshadow">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="pre-padding-std">
                                                <div className="columns">
                                                    <div className="column is-half">
                                                        <Input status="secondary" width="100%" required name="name" type="text" value={name} onChange={this.handleChange} >
                                                            <Description title="Your Name" />
                                                        </Input>
                                                    </div>
                                                    <div className="column is-half">
                                                        <Input status="secondary" width="100%" required name="email" type="email" value={email} onChange={this.handleChange} >
                                                            <Description title="Your Email" />
                                                        </Input>
                                                    </div>
                                                </div>
                                                <Input status="secondary" width="100%" required name="company" type="text" value={company} onChange={this.handleChange} >
                                                    <Description title="Your Company or Website" />
                                                </Input>

                                                <Divider />

                                                <Input status="secondary" width="100%" name="subject" type="text" value={subject} onChange={this.handleChange} >
                                                    <Description title="Message Subject(s)" />
                                                </Input>

                                                <Divider />

                                                <Description title="Your Message" />
                                                <Textarea width="100%" status="secondary" required name="message" value={message} onChange={this.handleChange} ></Textarea>
                                            </div>
                                            <div className="contact-footer">
                                                <div className="columns">
                                                    <div className="column is-two-thirds">
                                                        <Text p className="pre-white pre-wnorm center">Or email us at
                                                            <a href="mailto:ryan@ryanwans.com" className="pre-white" style={{fontWeight: '600'}}> contact@project-aeolus.com</a>
                                                        </Text>
                                                    </div>
                                                    <div className="column is-one-third" type="submit">
                                                        <Button type="success" ghost className="pre-bold" id="contact-btn" style={{marginTop: '8px'}} onClick={() => {
                                                            const { name, email, subject, message, company } = this.state;
                                                            const templateParams = {name: name,email: email,subject,message,ip: window.ip||"Unidentifiable", company};
                                                            emailjs.send(
                                                                'service_ph76heb',
                                                                'template_84bn9hv',
                                                                templateParams,
                                                                'user_0ao9us6kmutEhxx4EURjH'
                                                            )
                                                            this.resetForm();
                                                        }}>
                                                            <Text p style={{fontWeight: '600', fontSize: '13px'}}>Send Message</Text>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Section>
            </div>
        )
    }
}

export default Contact;