import React from 'react';

// Bulma Imports
// import { Container } from 'reactbulma';

// Geist Imports

// Ant Design Imports
import { Button } from 'antd';

// Component Imports
import logo from '../img/NewLogoTransWide.svg'

// Styled Imports
import LoginButton from './LoginButton';

// Variables

class NavBar extends React.Component {

    render() {
        return (
            <nav className="navbar is-fixed-top" style={{paddingTop: "10px", paddingBottom: "10px"}} role="navigation" aria-label="main navigation" id="aeolusglobals_navbar">
            <div className="container">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/" style={{padding: '0', paddingLeft: '20px', maxHeight: '100px'}}>
                        <img alt="logo" style={{height: '45px', maxHeight: '100px'}} src={logo} />
                    </a>
                
                    <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
            
                <div id="navbarBasicExample" className="navbar-menu">

                    <div className="navbar-end nav-access">
                    
                        <a className="navbar-item lhead" href="/tech">
                            Technology
                        </a>

                        <a className="navbar-item lhead" href="/products">
                            Products
                        </a>

                        <a className="navbar-item lhead" href="/services">
                            Services
                        </a>
                    
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link lhead">
                                More
                            </a>
                    
                            <div className="navbar-dropdown">
                                <a className="navbar-item" href="/about">
                                    About Us
                                </a>
                                <a className="navbar-item" href="/graphics">
                                    Graphics
                                </a>
                                <a className="navbar-item" href="/jobs">
                                    Jobs
                                </a>
                                <a className="navbar-item" href="/news">
                                    News
                                </a>
                                <a className="navbar-item" href="/contact">
                                    Contact
                                </a>
                                <hr className="navbar-divider" />
                                <a className="navbar-item" href="/report-issue">
                                    Report an issue
                                </a>
                            </div>
                        </div>
                        <hr className="navbar-divider" />
                        <a className="navbar-item lhead" href="/login" style={{paddingLeft: '2px', paddingRight: '2px'}}>
                            <LoginButton />
                        </a>
                        <a className="navbar-item lhead" href="/contact" style={{paddingLeft: '2px', paddingRight: '2px'}}>
                            <Button type="primary" className="lhead">Contact Us</Button>
                        </a>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default NavBar;