import React from 'react';

import { Container, Section } from 'reactbulma';
import { Text, Spacer } from '@geist-ui/react';

class MiniHeader extends React.Component {
    render() {
        return (
            <Container style={{alignContent: 'center', justifyContent: 'center', textAlign: 'center'}}>
                <Section style={{textAlign: 'center', maxWidth: '800px', alignSelf: 'center', justifySelf: 'center', display: 'inline-block'}}>
                    <Spacer y={2} className="is-hidden-mobile" />
                    <Text type="success" style={{letterSpacing: '3px', fontSize: '14px!important'}}>
                        {this.props.subject}
                    </Text>
                    <Text h1 className="pre-bigHeader pre-textgrad1">
                        {this.props.title}
                    </Text>
                    <Spacer y={2} className="is-hidden-mobile" />
                </Section>
            </Container>
        )
    }
}

export default MiniHeader