import React from 'react';
import { Link } from 'react-router-dom';

// Bulma Imports
// import { Container } from 'reactbulma';

// Geist Imports
import { Text } from "@geist-ui/react";

// Ant Design Imports
import { Space, Button } from 'antd';

// Component Imports

// Styled Imports
import Const from '../img/construction.svg';

// Variables

class NoAccess extends React.Component {
    render() {
        return (
            <div style={{minHeight: '800px'}} className="dotted-bg-scatter">
                <div className="container" style={{textAlign: 'center'}}>
                    <div style={{paddingTop: '120px', maxWidth: '500px', margin: 'auto'}} className="is-centered ">
                        <img src={Const} alt="No Access" style={{maxWidth: '350px', marginBottom: '55px'}} />
                        <Text h3 style={{fontWeight: '500'}}>This page is currently under construction</Text>
                        <Text p style={{paddingBottom: '15px'}}>
                            To view this page, you must be a Project Aeolus employee and log in with your Project Aeolus account. If you are logged in and still having trouble, contact the PA Development Team or Ryan.
                        </Text>
                        <Space>
                            <Button style={{fontWeight: '600'}} type="primary">
                                <Link to="/login">Log In</Link>
                            </Button>
                            <Button type="text">
                                <Link to="/contact?to=pass">Contact Dev Team &rarr;</Link>
                            </Button>
                        </Space>
                    </div>
                </div>
            </div>
        )
    }
}

export default NoAccess;