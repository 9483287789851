import React from 'react';

import { Container } from 'reactbulma';
import { Text, Divider } from '@geist-ui/react';

import { Tabs } from 'antd';
import MiniHeader from './MiniHeader'


const { TabPane } = Tabs;
class About extends React.Component {
    render() {
        return (
            <div>
                <MiniHeader title="About Project Aeolus" />
                <Divider />
                <Container>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Our Mission" key="1">
                            <Text className="rhead">
                                Mission Statement
                            </Text>
                            <Divider />
                            <Text p>
                                Our mission at Project Aeolus is to change how drone fleets and operations 
                                are managed and undertaken. To do this, we here at Project Aeolus create 
                                innovative solutions to be implemented by drone systems to create autonomy
                                and ease-of-use for any use case. We offer solutions in various weak points 
                                often seen in drone fleets today, such as charging platforms, data analytics 
                                platforms, and communications solutions. Additionally, we offer solutions 
                                in a wide variety of markets, such as final-mile delivery, industrial applications,
                                military, and drone-ops.
                            </Text>
                            <br/>
                            <Text className="rhead">
                                Our Work
                            </Text>
                            <Divider />
                            <Text p>
                                By offering products for every step in the process, we help our customers to 
                                achieve full autonomy for their drone systems, eliminating hundreds of thousands 
                                of dollars in expenses every year. <br/><br/>Our wireless charging products completely eliminate 
                                the need for human interaction on-site for drone fleets. This allows you to decrease 
                                interdependence, increase autonomy, and reduce expenses all around. Moreover, our 
                                products optimize your drone's charging based off of it's battery chemistry, thus saving you 
                                money by increasing battery lifespan.<br/><br/>
                                Shifting away from physical products, our robust communication pipelines and data 
                                analytics tools allow for our customers to aggregate the most detailed and important 
                                data points from their drone fleets. By creating communication between the drone and 
                                charging pad, we can also port data to and from the drone from the dashboard, allowing 
                                for drone telemetry data aggregation in realtime.
                            </Text>
                        </TabPane>
                        <TabPane tab="Team Aeolus" key="2">
                        Content of Tab Pane 2
                        </TabPane>
                        <TabPane tab="Updates" key="3">
                        Content of Tab Pane 3
                        </TabPane>
                    </Tabs>
                    <br/>
                </Container>
            </div>
        )
    }
}

export default About;