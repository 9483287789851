import React from 'react';
import { Link } from 'react-router-dom';

// Bulma Imports
// import { Container } from 'reactbulma';

// Geist Imports
import { Text } from "@geist-ui/react";

// Ant Design Imports
import { Button } from 'antd';

// Component Imports

// Styled Imports

// Variables

class Tagline extends React.Component {
    render() {
        return (
            <div style={{width: '100%', borderTop: '1px solid #EAEAEA', paddingTop: '50px', paddingBottom:'50px'}}>
                <div className="container">
                    <div className="columns">
                        <div className="column is-half has-text-centered">
                            <Text h1>{this.props.message}</Text>
                        </div>
                        <div className="column is-half has-text-centered">
                            <Button type="primary" size="large" style={{fontSize: '16px', fontWeight: '600', marginTop: '15px'}}>
                                <Link to="/contact">Contact Team Aeolus</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Tagline;