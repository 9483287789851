window.State = window.State || {};
window.State.vitals = window.State.vitals || [];

const UniversalStates = {
    push: (key, value) => {window.State[key] = value;},
    pull: (key) => {return window.State[key];},
    vitals: (value) => {
        window.State.vitals.push(value);

        window.analyticsVitals(value);
    }
}

export default UniversalStates;