import { Route } from 'react-router-dom'
import Default from './js/page';

// Bulma Imports
import { Container, Section } from 'reactbulma';

// Geist Imports

// Ant Design Imports
import { Layout } from 'antd';

// Component Imports
import NavBar from './components/NavBar';
import Index from './components/Index';
import Foot from './components/Foot';
import NoAccess from './components/NoAccess';
import Contact from './components/Contact';
import Construction from './components/Construction'

// Styled Imports
import theme from './css/theme.css';
import About from './components/About';

// variables
const { Content } = Layout;

function Wrapper() {
  Default()
  return (
    <div className="App" style={theme}>
      <Container>
        <NavBar />
      </Container>
      <Section style={{marginTop: '25px', padding: '10px'}}>
          <Content>
            <Route exact path="/" component={Index} />
            <Route exact path="/tech" component={NoAccess} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />

            <Route exact path="/products" component={Construction} />
            <Route exact path="/services" component={Construction} />
            <Route exact path="/graphics" component={Construction} />
            <Route exact path="/jobs" component={Construction} />
            <Route exact path="/news" component={Construction} />
          </Content>
      </Section>
      <Foot />
    </div>
  );
}

export default Wrapper;
