import React from 'react';
import ReactDOM from 'react-dom';
import Wrapper from './Wrapper';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { GeistProvider, CssBaseline } from '@geist-ui/react'

import UniversalStates from './js/state';

const ApplicationClient = new ApolloClient({
    uri: 'https://graphql.project-aeolus.com/internet-facing',
    cache: new InMemoryCache()
});

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={ApplicationClient}>
      <GeistProvider>
        <Auth0Provider
          domain="projectaeolus.us.auth0.com"
          clientId="K5lbk7M3sX2gYAzNb8ekqzJlaY1FYAwM"
          redirectUri={window.location.origin}
        >
          <CssBaseline />
          < Wrapper />
        </Auth0Provider>
      </GeistProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals(UniversalStates.vitals);
UniversalStates.push("load", Date.now());